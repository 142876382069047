.image-and-text-container {
	position: relative;
}

.image-and-text-container .overlay-text {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	color: #f4f3f3;
	font-weight: bold;
	font-size: 1.5rem;
	opacity: 1;
	z-index: 3;
	transition: opacity 0.3s ease;
}

.image-and-text-container:hover .overlay-text {
	opacity: 0;
}

/* .home-category-links-container {
	-webkit-transition: all 400ms ease-out;
	-moz-transition: all 400ms ease-out;
	-o-transition: all 400ms ease-out;
	-ms-transition: all 400ms ease-out;
	transition: all 400ms ease-out;
} */
.home-category-links-container h1 {
	opacity: 0;
	transform: translateY(15px);
	transition: opacity 0.4s, transform 0.4s;
}

.home-category-links-container:hover h1 {
	opacity: 1;
	transform: translateY(0);
}

/* .category-home-image-container-column .i:hover {
	opacity: 1;
}
.category-home-image-container-column .i .fade-up {
	opacity: 1;
	transition-delay: 0s;
	transition-duration: 0.4s;
} */
/* .category-home-image-container-column
	.home-category-links-container:hover
	.i
	h1 {
	opacity: 1;
	transform: translateY(-30px);
	-webkit-transform: translateY(-30px);
} */
.category-home-image {
	height: 422px;
	object-fit: cover;

	width: 300px;
	filter: drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.2));
	transition: transform 0.6s;
}

.category-home-row {
	--bs-gutter-x: 0 !important;
}
.home-category-header {
	font-weight: bold;
	/* padding-left: 8rem;
	padding-right: 8rem; */
}

.category-home-image-container {
	background-image: url(../../../../Assets/AboutUs/background.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
}
.category-home-image-container-column {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 270px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.home-category-links-container {
	position: absolute;
	display: flex;
	align-items: center;
	flex-direction: column;
	top: 0;
	text-align: center;
	/* visibility: hidden; */
	opacity: 0;
}
.category-home-image-container-column:hover .category-home-image {
	transform: scale(1.1);
}

.category-home-image-container-column:hover .home-category-links-container {
	/* visibility: visible; */
	/* opacity: 1; */
	cursor: default;
	background-color: black;
	opacity: 0.7;
}

@media only screen and (max-width: 850px) {
	.category-home-image {
		display: flex;
		flex-direction: column;

		margin-bottom: 1rem;
	}
}
