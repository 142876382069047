.icon-title {
	font-weight: 600;
	text-align: left;
}
.icon-items-data {
	display: flex;
	gap: 20px;
}
.icon-image {
	height: 70px;
	width: 80px;
}
.icon-text {
	width: 350px;
}

@media only screen and (max-width: 1200px) {
	.icon-text {
		width: auto;
	}
}

@media only screen and (max-width: 538px) {
	.icon-text {
		font-size: 0.8rem;
		max-width: 350px;
		width: auto;
	}
}
