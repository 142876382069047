@keyframes slide-in {
	0% {
		height: 0;
	}
	100% {
		height: auto;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes tada {
	0% {
		transform: scale(1);
	}
	10%,
	20% {
		transform: scale(0.9) rotate(-3deg);
	}
	30%,
	50%,
	70%,
	90% {
		transform: scale(1.1) rotate(3deg);
	}
	40%,
	60%,
	80% {
		transform: scale(1.1) rotate(-3deg);
	}
	100% {
		transform: scale(1) rotate(0);
	}
}
.small-white-text {
	display: flex;
	justify-content: flex-start;
	font-weight: 600;
	color: #016245;
	width: 100.42px;
	letter-spacing: 3px;
	font-size: 11px;
	background-color: white;
}
.small-green-text {
	display: flex;
	justify-content: flex-start;
	font-weight: 600;
	width: 132.42px;
	color: white;
	letter-spacing: 3px;
	font-size: 11px;
	background-color: #016245;
}

.animate-arrow:active {
	box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
}
.home-carousel-image {
	object-fit: cover;
	max-height: 800px;
	filter: drop-shadow(6px 4px 8px rgba(0, 0, 0, 0.2));
	border-radius: 10px;
}
.carousel-home-border-right-container {
	border-right: 50px solid #016245;
	padding-right: 1rem;
}
.animate-arrow:hover {
	animation: tada 1s infinite;
	transform: scale(40);
	/* animation: pulse 0.5s infinite; */
}

.home-carousel-text {
	padding-left: 4rem !important;
	padding-right: 2rem !important;
}
.image-Brief-description {
	font-size: 1.3rem;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-align: justify;
}
.home-carousel-button {
	font-size: 1.2rem !important;
}
@media only screen and (max-width: 450px) {
	.home-carousel-text {
		padding-left: 2rem !important;
		padding-right: 1rem !important;

		/* display: flex;
		justify-content: center;
		align-items: center; */
	}
	.carousel-home-border-right-container {
		border-right: none !important;
		padding-right: 0rem !important;
	}
	.carousel-home-border-right-container-row {
		--bs-gutter-x: 1rem !important;
	}
}
