.contact-form-paragraph {
	text-align: left;
	color: white;
	font-size: 0.9rem;
}
.contact-form-header {
	font-size: 1.3rem;
	font-weight: 700;
	text-align: left;
	color: white;
}
.contact-form {
	position: relative;
	background: rgba(1, 98, 69, 0.85);
}
.contact-form::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: url(../../Assets/contactForm/contactForm.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: -1; /* This will put the image behind the content of the div */
}
.check-box-text {
	color: white;
	font-size: 0.8rem !important;
}
.form-contact-check-box {
	text-align: left;
	color: white;
}
.form-contact-check-box a {
	color: white;
	border-bottom: 1.5px solid white;
	text-decoration: none;
}
.form-contact-check-box a:hover {
	color: white;
}
.ant-checkbox-input:hover {
	color: white !important;
}
.contact-form-button {
	display: flex;
	background: #323334;
	font-weight: 500;
	color: white;
	border-radius: 0;
	border: none;
	align-items: center;
}
.ant-checkbox-inner:hover {
	background-color: white;
}
.form-contact-check-box .ant-checkbox-checked .ant-checkbox-inner {
	background-color: transparent !important;
	border-radius: 0 !important;
}

.form-contact-check-box .ant-checkbox .ant-checkbox-inner {
	border-width: 1px;
	width: 12px; /* Adjust these values as per your need */
	height: 12px;
	border-color: white;
	background-color: transparent;
	border-radius: 0 !important;
}
.contact-form-input::placeholder {
	color: white;
	opacity: 1; /* Firefox */
}
.contact-form .contact-form-input {
	background: rgba(245, 244, 244, 0.8);
	border: none;
	outline: none;
	border-radius: 0 !important;
	border-bottom: 1px solid white;
	background-color: transparent;
	color: white;
}
.contact-form-button-container {
	display: flex;
	justify-content: flex-end;
}
@media only screen and (max-width: 450px) {
	.contact-form-button-container {
		justify-content: center !important;
	}
}
