.table-analyses-cover {
	object-fit: cover;

	filter: drop-shadow(6px 6px 10px rgba(0, 0, 0, 0.25));
	border-radius: 10px;
}
.for-patients-header {
	max-width: 800px;
	font-weight: 800;
}
.table-analyses {
	border-collapse: separate;
	border-spacing: 0 5px;
}
.AnalysesTable {
	background-image: url("../../../../Assets/ForPatients/for-patient-background.png");
	background-position: left top;
	background-repeat: no-repeat;
	background-size: auto 110%;
	padding-top: 1rem;
}

.table-analyses td,
th {
	border: 1px solid #f7f5f5 !important;
	border-right: none !important;
	border-top: none !important;
	box-shadow: 0px 2px 1px rgba(62, 62, 62, 0.5);
	background-color: white;
}
.table-analyses td {
	font-weight: 400;
}
.table-analyses tr:last-child td:first-child {
	border-bottom-left-radius: 10px; /* Adjust the border radius */
}

.table-analyses tr:last-child td:last-child {
	border-bottom-right-radius: 10px; /* Adjust the border radius */
}
.table-analyses th {
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
}
.analyses-table-container {
	background-color: rgba(255, 255, 255, 0.694);
	box-shadow: -2px 5px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}
.description-under-analyses-table {
	border-radius: 10px;
	background: rgba(1, 97, 69, 0.95);
	box-shadow: -2px 5px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
	color: white;
	text-align: left;
	font-size: 1.3rem;
}
@media only screen and (max-width: 540px) {
	.table-analyses-cover {
		height: auto;
		object-fit: cover;
	}
}
