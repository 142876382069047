.menu-card-image {
	object-fit: cover;
	transition: transform 0.9s ease-out;
	height: 364px;
	width: 100%;
}
.menu-card-image:hover {
	transform: scale(1.2);
}
.menu-card {
	text-align: left;
}
.menu-card-title h3 {
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 33px;
	color: black;
}
.menu-card-article {
	line-height: 39px;
	text-decoration: none;
	color: #323334;
}
.menu-card-article:hover {
	color: grey;
}
.menu-card-image-container {
	/* position: relative; */
	overflow: hidden;
	box-shadow: 2px 8px 8px rgba(8, 8, 8, 0.2);
	width: 100%;
}
