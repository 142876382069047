.analyses-table-button .select-icon-left .ant-select-arrow {
	display: none !important;
}
.analyses-table-button .ant-select-selection-item {
	font-weight: bold !important;
}
.analyses-table-button .ant-select-selector {
	height: 40px !important;
	border-top: none !important;
	border-bottom-right-radius: 10px !important;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.5);
}
