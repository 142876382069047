.Brief-description {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-align: justify;
}

.animation-text {
	font-weight: 600;
	font-size: 1rem;
	cursor: pointer;
}

.Brief-description-header {
	font-weight: bold;
}
.brief-description-button {
	border-radius: 105px;
	display: flex;
	font-weight: 700;
	align-items: center;
	border: 2px solid black;
}
/* .brief-description::before {
	content: "";
	display: block;
	height: 70px;
	margin-top: -70px;
	visibility: hidden;
} */
.font-weight-bold {
	font-size: 1.8rem;
}
@media only screen and (max-width: 450px) {
	.Brief-description ul {
		padding-left: 0 !important;
	}
}
