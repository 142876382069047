.newsletter {
	background-color: #e6e4e4;
	/* font-family: Segoe UI Emoji, Segoe UI Symbol; */
}

.newsletter-input-item {
	max-width: 400px !important;
	margin-left: 1rem;
}
.newsletter-form-input {
	border-radius: 8px;
	height: 35px;
}
.newsletter-form {
	display: flex;
}
.newsletter-button {
	color: white;
	border-radius: 8px;
	background-color: #636363;
	height: 35px;
	margin-top: 0.8rem;
}
.newsletter-button:hover {
	color: white;
	border-radius: 8px;
	background-color: #636363 !important;
	height: 35px;
	margin-top: 0.8rem;
}

.newsletter .ant-form-item-required {
	font-size: 1.1rem !important;
}

.newsletter .ant-form-item-required::before {
	color: #636363 !important;
}
@media only screen and (max-width: 450px) {
	.news-letter-header {
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
		text-align: justify;
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
