.footer-col1 h3 {
	font-weight: 700;
	font-size: 1.4rem;
	color: #323334;
}
.footer-col2 h3 {
	font-weight: 700;
	font-size: 1.4rem;
	color: #323334;
}
.footer-col1 {
	max-width: 180px;
}
.footer-col2 {
	max-width: 190px;
}
.footer-social-media-container {
	display: block;
	width: 170px;
	font-weight: bold;
}
.footer-social-media-icon {
	font-size: 1.25rem;
	color: #016245;
}
.footer-bar {
	text-align: center;
	background-color: #d9d9d9;
	font-weight: 300;
	font-size: 0.7rem;
}

.footer {
	background-color: #ffffff;
	text-align: left;
	padding-bottom: 0% !important;
}
.footer-col2-container {
	display: flex;
	justify-content: flex-end;
}
.footer-link {
	text-decoration: none;
	color: #323334;
}
.footer-link:hover {
	color: #b9b7b7;
}
.footer-description-title {
	font-weight: 700;
	font-size: 1.4rem;
}
.footer-col2-span {
	text-align: left;
}
.footer-description-paragraph {
	font-weight: 700;
}
@media only screen and (max-width: 450px) {
	.footer-col2-container {
		display: flex;
		justify-content: flex-start;
	}
}
