.icon-items-list-header {
	font-weight: bold;
}
.text-under-icon {
	text-align: justify;
	hyphens: auto;
}
.icon-items-row {
	margin: 20px 0 20px 0;
}
.icon-items-list {
	justify-content: center;
	display: grid;
	grid-row-gap: 10px;
}
