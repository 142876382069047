.service-brief-description-before-last {
	padding-left: 5rem !important;
	padding-right: 5rem !important;
}
@media only screen and (max-width: 450px) {
	.service-brief-description-before-last {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
}
