.header {
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	opacity: 0.8;
	z-index: 3;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.33),
		0px -4px 10px rgba(0, 0, 0, 0.33);
	transition: background-color 0.5s ease-in-out;
}
.web-app-header .scroller {
	background-color: rgba(50, 51, 52, 0.97);
	color: white !important;
	background-image: url(../../Assets/Logo/headerLogo/HeaderLogo.png);
	background-size: auto 130%;
	background-repeat: no-repeat;
	background-position: left center;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.33),
		0px -4px 10px rgba(0, 0, 0, 0.33);
}
.web-app-header .scroller .link-no-text-decoration {
	color: white !important;
}

.menu-icon {
	font-size: 4rem !important;
	height: 0.5rem !important;
	width: 0.5rem !important;
	background: #323334;
}
.header-text {
	font-size: 2rem;
	font-weight: 700;
}
.under-header-text {
	font-weight: 400;
	font-size: 1.5rem;
}
.header-text-background {
	text-align: left;
}
.under-header-text-scroll {
	position: relative;
	z-index: 2;
}

.under-header-text-scroll::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100%; /* adjust as needed */
	height: 14px; /* thickness of the border */
	background: #014e37b2;
	z-index: -1;
	transform: translateX(-50%); /* center the border */
}
