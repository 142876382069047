.menu-tab-title {
	/* display: flex;
	flex-direction: column; */
	/* justify-content: center;
	align-items: center; */
	font-size: 1rem;
	font-weight: 700;
}

.ant-tabs-nav {
	width: 100% !important;
	background-color: #bbbbbb;
	padding-top: 1.5rem;
}

.ant-tabs-tab {
	display: flex;
	flex: 1;
	padding-left: 2rem;
	justify-content: center;
}
.ant-tabs .ant-tabs-tab:hover {
	color: black !important;
}

.ant-tabs-nav > div:nth-of-type(1) {
	display: unset !important;
	width: 100% !important;
}
.ant-tabs-tab-active {
	background-color: white !important;
	color: black !important;
	border: none !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: black;
}
.ant-tabs-tab.ant-tabs-tab-active {
	border-bottom: none !important;
	z-index: 2;
}
/* @media only screen and (max-width: 990px) {
	.menu-tabs {
		display: none;
	}
} */
