.card-doctor-home-container {
	background-color: transparent;
	border-radius: 0;
}
.home-doctor-row {
	padding-left: 3rem !important;
	padding-right: 3rem !important;
}

.home-doctor .ant-card-body {
	padding-bottom: 0 !important;
}
.progress-bar {
	width: 60px !important;
	background-color: #323334 !important;
	--progress: 0%;
}
.progress {
	height: 6px !important;
	background-color: #016245 !important;
	border-radius: 0 !important;
	animation: shine 4s ease-in infinite, end 1s ease-out 1 7s;
	transition: width 3s ease 3s;
}

.home-doctor-text-header {
	font-size: 0.9rem;
	text-align: left;
	color: #5d5f6d;
}
.home-doctors-animation-text-container {
	flex-direction: row;
	align-items: center;
	bottom: 0;
	padding-bottom: 1rem;
	margin-right: 6rem !important;
	justify-content: end;
	font-size: 1.5rem;
	font-weight: bold;
}
@media only screen and (max-width: 880px) {
	.home-doctors-animation-text-container {
		padding-right: 0 !important;
		justify-content: center;
	}
}
@media only screen and (max-width: 450px) {
	.home-doctor-row {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.home-doctors-progress-col {
		align-items: center !important;
		justify-content: center !important;
	}
	.home-doctor-h {
		font-size: 3rem !important;
	}
	.second-progress-text {
		width: 50px;
	}
	.react-reveal {
		/* width: auto !important; */
	}
	.home-doctor-text-header {
		font-size: 1.1rem;
		text-align: left;
		color: #5d5f6d;
	}
	.second-progress-text {
		font-size: 1rem;
	}
	.first-progress-text {
		margin-bottom: 20px !important;
		padding-top: 3px;
	}
	.third-progress-text {
		width: 50px !important;
	}

	.second-progress-text-width {
		width: 80px !important;
	}
}
