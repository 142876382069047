.ellipse {
	width: 566px;
	height: 525.99px;
	background: #016245;
	border-radius: 50%;
	position: relative;
}
.s .h2 {
	display: flex;
	align-items: flex-end;
}
.home-excursion-shape-one {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	max-width: 415px;
	margin-left: 10px;
	height: 250px;
}
.home-excursion-shape-one-sub {
	padding-left: 5px;
}

.home-excursion-shape-one-text {
	position: absolute;
	bottom: 0;
	margin-left: 130px;
	color: white;
	font-weight: bold;
	z-index: 2;
}
/* .small {
	width: 555.25px;
	height: 403.62px;
	background: #016245;
	transform: rotate(-5.84deg);
} */
.ellipse-image {
	position: absolute;
	right: 0;
	bottom: 4px;
	max-width: 545px;
	max-height: 515px;
	border-radius: 50%;
}
.home-excursion-button {
	background-color: #323334;
	color: white;
	font-size: 1.2rem;
	border-radius: 0;
	display: flex;
	font-weight: 600;
	align-items: center;
}
.home-excursion-title-one {
	font-size: 2.8rem;
	font-weight: bold;
}
.home-excursion-nutrition {
	height: 350px;
	position: relative;
}

.home-excursion-nutrition-shape {
	width: 100%;
	height: auto;
	max-width: 420px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
.home-excursion-nutrition-image {
	margin-top: 45px;
	width: 100%;
	max-width: 410.43px;
	max-height: 303.62px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
.home-excursion-button-container {
	display: flex;
	justify-content: flex-end;
}
.home-excursion-Excursions-image {
	margin-top: 45px;
	max-width: 390.43px;
	height: 303.62px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
}
.home-excursion-Accommodation-image {
	margin-top: 100px;
	width: 390.43px;
	width: 100%;
	height: 400.62px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
}

.home-excursion-title-two {
	display: inline-block;
	vertical-align: top;
	font-size: 8rem;
	font-weight: 700;
	color: #016245;
}
@media only screen and (max-width: 1030px) {
	.ellipse {
		width: 466px;
		height: 425.99px;
	}
	.ellipse-image {
		max-width: 435px;
		max-height: 410px;
	}
}

/* @media only screen and (max-width: 988px) {
	.Home-excursion-first-shape-col {
		justify-content: center;
	}
} */
@media only screen and (max-width: 966px) {
	.home-excursion-title-one {
		font-size: 2.8rem;
	}
}
@media only screen and (max-width: 596px) {
	.ellipse {
		width: 350px;
		height: 325.99px;
	}
	.ellipse-image {
		max-width: 335px;
		max-height: 310px;
	}
	.home-excursion-button-container {
		justify-content: center;
	}
}
/* @media only screen and (max-width: 515px) {
	.home-excursion-nutrition-shape {
		display: none;
	}
} */

@media only screen and (max-width: 495px) {
	.home-excursion-title-one {
		font-size: 1.5rem;
	}
	.home-excursion-title-two {
		font-size: 5rem;
	}
}
