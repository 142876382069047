.blog-side-image {
	height: 450px;
	object-fit: cover;
}
.blog-side-image-text {
	text-align: left;
	font-size: 1.1rem;
	color: #323334;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	font-weight: 500 !important;
	text-align: justify;
}
.blog-side-image-header {
	line-height: 2.8rem !important;
	font-weight: bold;
	text-align: left;
}
@media only screen and (max-width: 989px) {
	.blog-side-image-header {
		line-height: 1.2 !important;
		text-align: left;
		font-weight: bold;
	}
}
@media only screen and (max-width: 450px) {
	.blog-side-image-header {
		text-align: center;
		font-size: 1.4rem !important;
		font-weight: bold;
	}
}
