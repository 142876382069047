.standard-card {
	border: none;
	border-radius: 0 !important;
}
.standard-card-image {
	object-fit: cover;
	filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25)) !important;
	border-radius: 12px;
}
.ant-card-cover {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.standard-card-big-info-div {
	background-color: #f0f0f0;
	height: 100% !important;
	border: 1px solid #ccc;
}
.ant-card-meta-title {
	margin: 0 !important;

	font-weight: bold !important;
	white-space: normal !important;
}

.ant-card-cover {
	display: flex;
	justify-content: center;
	align-items: center;
}
