.flex-container {
	display: flex;
}
.flex-element {
	margin-bottom: 0 !important;
	margin-right: 5px;
	color: black;
	font-size: 1.1rem;
	font-weight: 700;
}

.menu-wrapper .slick-initialized {
	background-color: #c4c4c4 !important;
	display: flex;
	justify-content: center;
	padding: 10px 5px 10px 5px;
}

.slick-track {
	width: 100%;
}

.menu-item {
	display: flex;
	align-items: center;
	font-size: 0.75rem;
	font-weight: 700;
	color: black;
}

.menu-item:hover {
	color: white !important;
	text-decoration: none !important;
}

.ant-menu-item-selected {
	color: white !important;
}

.menu-item-active * {
	color: white !important;
	border: none !important;
}

.arrow-down {
	transform: rotate(90deg);
	color: black;
}

.menu-item:hover p {
	color: white !important;
}

.scrollable-menu {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.menu-wrapper {
	width: 100%;
	overflow-x: hidden;
}

.slick-slide {
	display: flex !important;
	justify-content: center;
	cursor: pointer;
}
