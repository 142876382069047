.left-title-home-cover-text {
	position: absolute;
	z-index: 3 !important;
	height: 100%;
	max-width: 600px;
	text-align: left;
	margin-left: 3rem;
	color: white;
	flex-direction: column;
}
.left-title-home-cover-container {
	position: relative;
	z-index: -2;
}
.logo-right {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 15%;
	font-size: 4rem;
}
.left-title-home-cover {
	height: 100vh;
	position: relative;
	object-fit: cover;
}
.clinic-name {
	-webkit-text-stroke: 1px white;
	-webkit-text-fill-color: transparent;
	position: relative;
}

.clinic-name::after {
	content: "";
	position: absolute;
	-webkit-text-stroke: 2px white;
	-webkit-text-fill-color: transparent;
	left: 1px;
	top: 1px;
	z-index: -1;
}
.clinic-name {
	font-size: 3rem;
	font-weight: 300;
}
.clinic-specialty {
	font-size: 3.5rem;
	font-weight: bolder;
	margin-left: 30px;
	text-align: left;
}
.image-overlay-container {
	position: relative;
}

.image-overlay-container::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(50, 51, 52, 0.3);
	pointer-events: none;
	z-index: 1;
}
.video-cover {
	object-fit: cover;
}
.home-cover-button {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	margin-top: 1.6rem;
}

@media only screen and (max-width: 880px) {
	.left-title-home-cover-text {
		margin-left: 2rem !important;
	}
	.left-title-home-cover {
		height: 45vh !important;
	}
	.clinic-name,
	.clinic-specialty {
		font-size: 1.6rem;
	}
	.home-cover-button {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}
}
@media only screen and (max-width: 600px) {
	.left-title-home-cover {
		height: auto;
	}
	.clinic-name,
	.clinic-specialty {
		font-size: 1.1rem;
	}
	.left-title-home-cover-text {
		max-width: 300px;
	}
}
