.about-us-Block-1 {
	max-height: 498px;
	object-fit: cover;
	overflow: hidden;
	border-top-right-radius: 100px;
	border-bottom-left-radius: 250px;
}
.about-block-space {
	margin: 0 7rem !important;
}
.about-us-Block-1-content {
	text-align: left;
	font-size: 1.14rem;
}
.about-us-Block-2 {
	max-height: 689px;
	border-top-left-radius: 250px;
	border-bottom-right-radius: 100px;
	object-fit: cover;
	overflow: hidden;
}
.about-us-Block-3 {
	max-height: 486px;
	border-top-left-radius: 100px;
	border-bottom-right-radius: 220px;
	object-fit: cover;
	overflow: hidden;
}
.carousel-image_about-us {
	width: 300px;
	height: 190px;
	object-fit: cover;
}
.carousel-about {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.ant-carousel .slick-dots {
	bottom: -1rem;
}
/* .carousel-about .ant-carousel .slick-dots{
	position: relative;
} */
.carousel-about .ant-carousel .slick-dots li button:before {
	font-size: 13px;
	color: #050606 !important; /* Change this to your desired color */
}

.carousel-about .ant-carousel .slick-dots li.slick-active button:before {
	color: #016245 !important; /* Change this to your desired active color */
}
/* .about-left-arrow {
	position: absolute;
	left: 42%;
	bottom: 1rem;
	font-size: 1.5rem;
	cursor: pointer;
	color: black;
}
.about-right-arrow {
	position: absolute;
	right: 42%;
	bottom: 1rem;
	font-size: 1.5rem;
	cursor: pointer;
	color: black;
} */
@media only screen and (max-width: 998px) {
	.about-block-space {
		margin: 0 4rem !important;
	}
	.about-us-Block-1 {
		object-fit: unset;
	}
}
@media only screen and (max-width: 772px) {
	.about-block-space {
		margin: 0 !important;
	}
	/* .about-us-Block-1 {
		object-fit: unset;
	} */
}
/* @media only screen and (max-width: 631px) {
	.about-right-arrow {
		right: 100px;
	}
	.about-left-arrow {
		left: 100px;
	}
} */
