.doctor-info-button {
	text-align: center;
	background-color: white;
}
.our-doctors-button {
	border-radius: 15px !important;
}
/* 
.doctor-image {
	max-width: 500px;
} */
/* .doctor-info-button-container {
	display: flex;
	justify-content: center;
} */
