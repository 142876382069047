.image-brief-description-header {
	font-weight: bold;
}
.brief-image-description-row {
	margin-left: 3rem;
	margin-right: 3rem;
}

.image-brief-description-section-container {
	padding-left: 3rem;
	padding-right: 3rem;
}
.image-brief-description-subtitle {
	font-weight: 700;
}
.image-Brief-description {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-align: justify;
	font-weight: 400;
}

.image-Brief-description-image {
	max-height: 700px;
	object-fit: cover;
	filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.381));
}
.image-brief-description-text-column {
	padding-left: 4rem !important;
	padding-right: 2rem !important;
}

/*  */
@media only screen and (max-width: 992px) {
	.image-brief-description-text-column {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
@media only screen and (max-width: 450px) {
	.brief-image-description-row {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.image-brief-description-section-container {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.border-right {
		border-right: none !important;
		padding-right: 0 !important;
	}
}
