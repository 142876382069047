.home-blog-grey-container {
	background-color: #d9d9d9;
	border-radius: 60px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	padding: 2rem;
	width: 100%;
	height: 200px;
}
.home-blog-section-image {
	object-fit: cover;
	width: 233px;
	height: 223px;
}
.home-blog-grey-container-circle-image {
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 233px;
	max-height: 223px;
	border-radius: 90px;
	overflow: hidden;
	object-fit: cover;
}

.home-blog-header {
	margin-right: 230px;
}
.home-blog-sub-header {
	margin-right: 230px;
}
.home-blog-header-left {
	margin-left: 248px;
}
.home-blog-sub-header-left {
	margin-left: 248px;
}
.circle-image img {
	width: 100%;
	height: 100%;
}
@media only screen and (max-width: 992px) {
	.blog-section-last-col {
		margin-bottom: 4rem;
	}
}
@media only screen and (max-width: 540px) {
	.home-blog-grey-container {
		height: 100px;
	}
	.home-blog-grey-container-circle-image {
		max-width: 133px;
		max-height: 123px;
		outline: none !important;
		outline-offset: 0;
	}
	.home-blog-section-image {
		width: 133px;
		height: 123px;
	}

	.home-blog-header {
		margin-right: 120px;
	}
	.home-blog-sub-header {
		margin-right: 120px;
	}
	.home-blog-header-left {
		margin-left: 128px;
	}
	.home-blog-sub-header-left {
		margin-left: 128px;
	}
}
