.frame-paragraph {
	background: rgba(255, 255, 255, 0.85);
	box-shadow: -2px 5px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
	border-radius: 12px;

	width: 80%;
	font-size: 1.3rem;
}
.frame-paragraph-text {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-align: justify;
}

@media only screen and (max-width: 1013px) {
	.frame-paragraph {
		width: 100%;
	}
}
