/* .ant-image-img {
	/* padding-left: 1rem !important;
	padding-right: 1rem !important; */
/* border-radius: 10px; */
.excursion-collapse-text {
	text-align: left;
}
/* .excurtion-mobile {
	display: none;
} */
.excurtion-desctop {
	display: none;
}
