.doctor-info-name {
	justify-content: center;
}
.doctor-info-content {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-align: justify;
}
.doctor-image {
	border-radius: 5px !important;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
	object-fit: cover;
}
.doctor-info-name-russian {
	font-weight: 400;
}
