.result-carousel-image-container {
	width: 60%;
	position: relative;
}

.prev-arrow,
.next-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 30px;
	z-index: 2;
}

.prev-arrow {
	left: -50px;
}
.slick-active Button {
	display: none;
}

.next-arrow {
	right: -50px;
}
.results-carousel-header {
	font-weight: bold;
}
@media only screen and (max-width: 820px) {
	.next-arrow,
	.prev-arrow {
		display: none;
	}
	.result-carousel-image-container {
		width: 80%;
	}
}
